<template>
  <div class="components-container">
    <!-- 输入框 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/08.png" alt="" />
        <p>代发人员历史</p>
      </div>
      <el-row :gutter="20" style="margin-top: 30px">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="发薪企业：">
                <el-select
                  v-model="form.humanEntId"
                  size="medium"
                  placeholder="全部"
                  style="width: 100%"
                  @change="searchState"
                  filterable
                >
                  <el-option
                    v-for="(item,index) in option"
                    :key="index"
                    :label="item.entName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="用工企业：">
                <el-select
                  v-model="form.entId"
                  size="medium"
                  placeholder="全部"
                  style="width: 100%"
                  @change="searchStates"
                  filterable
                >
                  <el-option
                    v-for="(item,index) in employmentOption"
                    :key="index"
                    :label="item.entName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="人员查询：">
                <el-input
                  v-model="form.personnelQuery"
                  placeholder="请输入姓名/手机号/身份证/银行卡"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-button type="primary" @click="inquire" size="medium" style="background: #6591f1">
              <i class="el-icon-search" style="margin-right: 7px"></i>查询</el-button>
            <el-button plain size="medium" @click="reset" style="background: #f7f8fa">
              <i class="el-icon-refresh-left" style="margin-right: 7px"></i>重置</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 表单 -->
    <div class="components-form">
      <el-table
        :data="pageList.list"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="small"
      >
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="身份证号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="accountNumber"
          label="银行卡号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="humanEntName"
          label="发薪企业"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="entName"
          label="所属用工企业"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共{{this.pageList.total}} 家企业 第 {{this.pageList.pageNum}} 页</p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :page-size="form.pageSize"
          :current-page="form.pageIndex"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageList.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "@/utils/request";
export default {
  name: 'markdown-demo',
  data() {
    return {
      loadingTable: false,
      form: {
        pageIndex: 1,
        pageSize: 15,
        entId: null,
        humanEntId: null,
        personnelQuery: "",
      },
      pageList:"",
      option:[],
      employmentOption:[],
    }
  },
  mounted(){
    this.payrollEnterprise()
    this.employmentEnterprises()
    this.getData()
  },
  methods: {
    payrollEnterprise() {
      instance({
        method: "post",
        url: "/v1/sysEnt/payConfigEnterprises",
      })
        .then((res) => {
          if (res.success) {
            this.option = res.data
            this.option.unshift({ id: null, entName: "全部" });
          }
        })
        .catch((err) => {});
    },
    employmentEnterprises() {
      instance({
        method: "post",
        url: "/v1/sysEnt/getEntList",
      })
        .then((res) => {
          if (res.success) {
            this.employmentOption = res.data
            this.employmentOption.unshift({ id: null, entName: "全部" });
          }
        })
        .catch((err) => {});
    },
     // 筛选方法
    // 状态
    searchState(val) {
      this.form.humanEntId = val;
      this.getData()
    },
    searchStates(val){
      this.form.entId = val;
      this.getData()
    },
    getData() {
      this.loadingTable = true;
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const { form } = this;
      const { pageIndex, pageSize,entId,humanEntId,personnelQuery } = form;
      instance({
        method: "post",
        url: "/v1/xReplacePerson/replacePersonList",
        data: {
          pageIndex,
          pageSize,
          entId,
          humanEntId,
          personnelQuery
        },
      })
        .then((res) => {
          if (res.success) {
            this.loadingTable = false;
            this.pageList = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
     // 查询
     inquire() {
      this.form.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 重置页面
    reset() {
      this.form.entId = null;
      this.form.humanEntId = null;
      this.form.personnelQuery = "";
      this.form.pageIndex = 1;
      this.form.pageSize = 15;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 翻页
    changePage(val) {
      this.form.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // f分页
    currentChange(val) {
      this.form.pageSize = val;
      this.form.pageIndex = 1;
      this.getData();
    },
  }
}
</script>
<style lang="less" scoped>
.components-container {
  margin: 20px;
  // background-color: #fff;
}
.components-input {
  width: 100%;
  height: 169px;
  background: #ffffff;
  padding: 20px;
  .tinymceSchedule_title {
    width: 100%;
    border-bottom: 1px solid #ececec;
    display: flex;
    height: 40px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      // line-height: 22px;
    }
    p:first-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  // padding: 20px;
}
.components-form {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  /deep/ .el-table {
      thead {
        height: 50px;
        background: #6591f1;
        font-weight: 600;
      }
      .el-table__cell.is-center {
        text-align: left;
      }
      .cell {
        padding-left: 30px;
      }
    }
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
/deep/ .el-input__inner {
    background: #f7f8fa;
    border: none;
  }
</style>


